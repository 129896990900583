$ASSISTANT_TYPE: it;
@import 'src/styles/variables';

.form-sub-list {
  --btn-width: 32px;
  display: flex;

  &__headers {
    h3 {
      flex: 1;
      margin: 0;
      text-align: center;
    }
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__item,
  &__headers,
  &__new-item {
    align-items: center;
    display: flex;
    & > * {
      flex: 1;
    }
  }

  &__btn-wrapper {
    flex: 0 0 var(--btn-width);
  }

  &__btn {
    padding: 12px;
  }
}
