$ASSISTANT_TYPE: it;
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
