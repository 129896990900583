$ASSISTANT_TYPE: it;
@import 'src/styles/colors';

.firebaseui-container.mdl-card {
  box-shadow: none;
}
.firebaseui-card-header {
  display: none;
}

.firebaseui-container {
  height: 100%;
  max-width: none;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .firebaseui-card-content {
      margin-top: auto;
      padding: 0 68px;
    }
  }

  .firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    background-color: #8e8e8e;
  }

  .firebaseui-card-actions {
    margin-top: auto;
    padding: 8px 68px 24px;

    .firebaseui-form-actions {
      align-items: stretch;
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 10;

      button {
        margin: 5px 0;

        &.mdl-button--colored {
          background: $secondary-color;
          border: 1px solid $secondary-color;
          border-radius: 56px;
          box-shadow: none;
          color: #ffffff;
        }

        &.mdl-button--primary {
          background-color: transparent;
          border: 1px solid #8e8e8e;
          border-radius: 56px;
          color: #8e8e8e;
        }
      }
    }
  }
}
