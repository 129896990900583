$ASSISTANT_TYPE: it;
.feedback-dialog {
  display: flex;
  height: 30px;
  justify-content: center;
  min-width: 60px;

  &__feedback-link {
    img {
      height: '30px';
      width: '30px';
    }
  }

  &__feedback-button {
    background: #fff;
    border-radius: 50px;
    box-sizing: border-box;
    color: #8e8e8e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 14px;
    outline: none;
    padding: 8px 6px;
    text-transform: none;
    width: 152px;
  }
}
